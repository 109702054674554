@import "ui/styles/mixins";
.container {
  padding: 0 var(--size-5);
  margin-top: var(--size-5);
  @media (min-width: 900px) {
    margin-top: var(--size-14);
  }
  @media (min-width: 1160px) {
    padding: 0;
  }
}

.bar {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 600px) {
    padding: 0;
    margin-bottom: 0;
  }
}

.follow {
  color: var(--accentPrimary);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  button:first-of-type {
    padding-right: var(--size-2);
    @media (min-width: 600px) {
      padding-right: var(--size-6);
    }
  }
}

.handle {
  color: var(--textDiscreet);
  margin-right: var(--size-4);
  display: flex;
  align-items: center;
  @media (min-width: 900px) {
    width: 50%;
  }
}

.inner {
  max-width: 400px;
  margin: 0 auto 30px;
  text-align: center;
  @media (min-width: 900px) {
    margin: 0 auto 65px;
  }
}

.avatar {
  width: min-content;
  margin: 0 auto var(--size-5) auto;
}

.name {
  margin-bottom: var(--size-4);
}

.left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  @media (min-width: 600px) {
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--size-6);

  > * {
    margin: 0 var(--size-2);
  }

  :global(.text-button) {
    margin: 0;
    margin-left: 15px;
    text-decoration: none;
  }
}

.stickerContainer {
  position: absolute;
  bottom: -10px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: -10px;
}

.multiBadge {
  bottom: -20px;
  align-items: center;
  justify-content: center;
  right: 0;
}

.sticker {
  background-color: var(--uiBlack);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--uiWhite);
  border: 2px var(--uiWhite) solid;
  padding: var(--size-1);
  width: 40px;
  height: 40px;
  margin: 0 2px;

  svg {
    width: 100%;
    height: 100%;
    color: var(--uiWhite);
  }
}

.librarianSticker {
  background: #f69338;
  cursor: pointer;
}

.supporterSticker {
  background: #278458;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--textWhite);
    filter: brightness(1.2);
  }
}
