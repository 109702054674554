@import "ui/styles/mixins";
.dotRow {
  display: flex;
}

.dot {
  display: none;
  @media (min-width: 600px) {
    padding-top: 1px;
    display: flex;
    align-items: center;
    margin: 1px var(--size-3) 0 var(--size-3);
  }
}
