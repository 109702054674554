@mixin darkMode {
  @media (prefers-color-scheme: dark) {
    @content;
  }

  &.dark {
    @content;
  }
}

@mixin darkModeAndHover {
  @media (prefers-color-scheme: dark) and (hover: hover) {
    @content;
  }

  @media (hover: hover) {
    &.dark {
      @content;
    }
  }
}