@import "ui/styles/mixins";
.item {
  user-select: none;
  display: flex;
  align-items: center;
  border: var(--outlineWidth) solid var(--uiOutline);
  position: relative;
  --isHover: 0;
  transition: all 200ms linear;
  @media (hover: hover) {
    &:hover {
      --isHover: 1;
      border-color: var(--uiInput);
      z-index: 1;
      @media (min-width: 600px) {
        .cover {
          transform: translateY(calc(var(--isHover) * -1px));
        }
      }
    }
  }
}

.inner {
  display: flex;
  padding: 20px;

  @media (min-width: 900px) {
    padding: 42px;
  }

  .coverOuter {
    display: flex;
    align-items: center;
    height: 172px;
    @media (min-width: 600px) {
      height: 172px;
    }
    @media (min-width: 900px) {
      height: 200px;
    }
  }

  .cover {
    position: relative;
    width: 80px;
    flex: 0 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 300ms ease;
    @media (min-width: 600px) {
      width: 110px;
      flex: 0 0 110px;
    }

    @media (min-width: 900px) {
      width: 130px;
      flex: 0 0 130px;
    }
  }
}

.stickers {
  display: flex;
  margin-bottom: 10px;

  @media (min-width: 600px) {
    margin-bottom: 14px;
  }
}

.yourStatus {
  margin-right: var(--size-2);
}

.info {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 600px) {
    padding-left: 30px;
    padding-right: 0;
  }

  @media (min-width: 900px) {
    padding-left: 40px;
  }
}

.title {
  @include limitTextLines(3);
}

.subtitle {
  font-family: Libre Baskerville;
  font-size: 14px;
  font-style: italic;
  margin: 5px 0;
  display: none;
  @include limitTextLines(2);
}

.author {
  color: var(--textDiscreet);
  margin-top: 5px;
  @include limitTextLines(1);
}

.controls {
  position: absolute;
  top: 18px;
  right: 10px;
  transition: opacity 200ms linear;
}
