@import "ui/styles/mixins";
.container {
  user-select: none;
  position: relative;
  border: var(--outlineWidth) solid var(--uiOutline);
  transition: all 200ms linear;
  --isHover: 0;
  
  @media (hover: hover) {
    &.isOptionsMenuOpen,
    &:hover {
      --isHover: 1;
      border-color: var(--uiInput);
      z-index: 1;

      .stickers {
        right: 40px;
      }

      .image {
        transform: translateY(calc(var(--isHover) * -1px));
      }
    }
  }

  .controls {
    position: absolute;
    top: 16px;
    right: 10px;
    transition: opacity 200ms linear;

    @media (min-width: 600px) {
      top: 18px;
    }
  }
  .outerButton {
    display: block;
    width: 100%;
    background-color: transparent;
    border: 0 none;
    padding: 0;
  }

  .box {
    min-height: 270px;
    position: relative;
    padding-top: 100%;
    @media (min-width: 350px) {
      padding-top: 130%;
    }
    @media (min-width: 600px) {
      padding-top: 124.6%;
    }
    @media (min-width: 900px) {
      padding-top: 100%;
    }
  }
}
.inner {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 20px 0;
}
.image {
  position: relative;
  transition: opacity 300ms ease;
  transition: transform 300ms ease;
  width: 64px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;

  @media (min-width: 900px) {
    width: 90px;
  }
}
.relative {
  position: relative;
  width: 100%;
}
.info {
  width: 100%;
  padding: 0 20px;
  text-align: center;
  margin-top: auto;
}
.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.authors {
  color: var(--textDiscreet);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

.subtitle {
  color: var(--textDiscreet);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  @media (min-width: 900px) {
    -webkit-line-clamp: 2;
  }
}

.yourStatus {
  position: absolute;
  top: 13px;
  left: 13px;
  display: flex;
  justify-content: space-between;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 0;
  }
}
.stickers {
  position: absolute;
  top: 13px;
  transition: all 0.2s ease;
  right: 13px;
  
  @media (hover: none) {
    right: 40px;
  }
}
