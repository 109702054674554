@import "ui/styles/mixins";
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.padding {
  padding: 58px 20px 37px 20px;

  @media (min-width: 1160px) {
    padding: 58px 0 37px;
  }
}

.left {
  display: flex;
  align-items: center;
}

.viewAll {
  display: inline;
}

.bookCount {
  margin-left: var(--size-3);
  @media (min-width: 600px) {
    margin-left: var(--size-5);
  }
}
