@import "ui/styles/mixins";
.container {
  width: 1200px;
  height: 630px;
  overflow: hidden;
  padding: 60px 70px 0 70px;
  display: flex;
  flex-direction: column;
  background-color: var(--uiWhite);
  position: relative;
}

.top {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  svg {
    width: 42px;
    height: 58px;
  }
}

.avatar {
  display: flex;
  justify-content: center;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -30px;
  width: 100%;
}

.title {
  margin-top: 30px;
  color: var(--textBlack);
  text-align: center;
  font-size: 54px;
  width: 100%;
}

.faded {
  margin-top: var(--size-5);
  text-align: center;
  font-size: 38px;
  color: var(--textSecondary);
}

.books {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: auto;
  gap: 60px;
  height: 180px;
}

.bookHolder {
  display: flex;
  height: 100%;
  width: 17%;
  img {
    width: 100%;
  }
  &:nth-child(1) {
    margin-top: 78px;
  }
  &:nth-child(2) {
    margin-top: 23px;
  }
  &:nth-child(3) {
    margin-top: 36px;
  }
  &:nth-child(4) {
    margin-top: auto;
  }
  &:nth-child(5) {
    margin-top: 18px;
  }
  &:nth-child(6) {
    margin-top: 42px;
  }
}

.square {
  width: 840px;
  height: 840px;
  display: flex;
  flex-direction: column;
  .profile {
    padding-top: 80px;
  }
  .bookHolder {
    justify-content: flex-end;
    &:nth-child(3) {
      margin-top: auto;
    }
    &:nth-child(4) {
      margin-top: 30px;
    }
  }
}

.portrait {
  width: 1080px;
  height: 1920px;
  .top {
    justify-content: center;
    margin-top: 200px;
  }
  .avatar {
    margin-top: 144px;
  }

  .title {
    margin-top: 120px;
  }

  .books {
    margin-top: 194px;
    height: 100%;
    flex-direction: row;
    gap: 40px;
    .column {
      flex: 0 1 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 40px;
      height: 100%;
      align-self: flex-end;

      .bookHolderPortrait {
        border: 1px solid var(--uiOutline);
        width: 100%;
        display: flex;
        align-items: flex-end;
        img {
          width: 100%;
        }
      }
      &:nth-of-type(1) {
        transform: translateY(40px);
      }
      &:nth-of-type(2) {
        transform: translateY(180px);
      }
      &:nth-of-type(3) {
        transform: translateY(140px);
      }
      &:nth-of-type(4) {
        transform: translateY(90px);
      }
    }
  }
}
