@import "ui/styles/mixins";
.container {
  text-decoration: none;
  overflow: hidden;
  border: var(--outlineWidth) solid var(--uiOutline);
  display: flex;
  flex-direction: column-reverse;
  height: 250px;
  margin: 0 0 30px;
  flex-direction: row;
  position: relative;
  z-index: 0;
  transition: border-color 200ms ease;
  @media (min-width: 600px) {
    height: 280px;
  }
  @media (hover: hover) {
    &:hover {
      border-color: var(--uiInput);
      z-index: 1;
    }
    &:hover {
      .images {
        .image {
          transform: rotate(2deg) translate3d(-50%, -50%, 0) scale(1.02);
        }
        .image:nth-child(2) {
          transform: rotate(-7deg) translate3d(0, -48%, 0) scale(1.02);
        }
        .image:nth-child(3) {
          transform: rotate(7deg) translate3d(0, -45%, 0) scale(1.02);
        }
      }
    }
  }
}
.info {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 50%;
  padding: 32px 20px 32px 30px;
  min-width: 0;
  @media (min-width: 900px) {
    padding: 32px 20px 32px 40px;
  }
}

.details {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: nowrap;
}
.user {
  display: flex;
  align-items: center;
}
.dot {
  display: flex;
  align-items: center;
  margin: 1px var(--size-2) 0 var(--size-2);
  padding-top: 1px;
}
.booksCount,
.user {
  color: var(--textDiscreet);
}

.booksCount {
  flex: 0 0 auto;
}

.images {
  transition: opacity 300ms ease;
  flex: 0 0 50%;
  position: relative;
  max-width: 300px;

  .image {
    width: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: rotate(2deg) translate3d(-50%, -50%, 0);
    z-index: 3;
    transition: all 0.4s ease;

    @media (min-width: 600px) {
      width: 90px;
    }
  }

  .image:nth-child(2) {
    width: 80px;
    left: 15%;
    transform: rotate(-5deg) translate3d(0, -48%, 0);
    z-index: 2;

    @media (min-width: 600px) {
      width: 90px;
    }
  }
  .image:nth-child(3) {
    width: 80px;
    left: auto;
    right: 15%;
    transform: rotate(5deg) translate3d(0, -45%, 0);
    z-index: 1;

    @media (min-width: 600px) {
      width: 90px;
    }
  }
}

.description {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: -1;
}

.title {
  h3 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (min-width: 600px) {
      -webkit-line-clamp: 7;
    }
  }
}
