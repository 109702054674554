@import "ui/styles/mixins";
.grid {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 -20px;
  overflow-x: hidden;
  padding-top: 1px;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1120px) {
    overflow-x: visible;
    width: calc(
      100% - var(--outlineWidth)
    ); // We shift all children by 1px to the left so the container needs to be 1px smaller
    margin: 0;
  }
  > a {
    margin: calc(-1 * var(--outlineWidth)) calc(-1 * var(--outlineWidth)) 0 0;
  }
}
