@import "ui/styles/mixins";
.container {
  display: block;
  width: 90px;
  height: 118px;
  flex: 0 0 auto;
}

.image,
.fallback {
  background: var(--uiOutline);

  &.small {
    height: 64px;
    width: 64px;
    font-size: 12px;
    border-radius: 5px;
  }

  &.medium {
    height: 90px;
    width: 90px;
    font-size: 22.5px;
    border-radius: 7.03125px;
  }

  &.fill {
    height: 100%;
    width: 100%;
    font-size: 52px;

    border-radius: 0; // hacky override
    @media (min-width: 600px) {
      border-radius: 7.03125px;
    }
  }
}

.image {
  display: block;
  object-fit: cover;
  color: transparent;
}

.fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textDiscreet);
}

.name {
  margin-top: var(--size-1);
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  position: relative;
  color: var(--textDiscreet);

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 97%);

    @include darkMode {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 97%);
    }
  }
}
