@import "ui/styles/mixins";
.grid {
  display: grid;
  grid-template-columns: 1fr;
  > * {
    margin: calc(-1 * var(--outlineWidth)) 0 0 0;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
    > *:nth-child(odd) {
      margin: calc(-1 * var(--outlineWidth)) calc(-1 * var(--outlineWidth)) 0 0;
    }
  }
}
