@import "ui/styles/mixins";
.list {
  position: relative;
  margin: 0 -20px;

  @media (min-width: 1120px) {
    margin: 0 0;
  }
}

.track {
  @include scrollingX;
  margin-top: 1px;
  overflow-y: hidden;
}

.row {
  display: flex;
}

// The regular book item, has a fixed size
.item {
  flex: 0 0 188px;
  margin-left: calc(-1 * var(--outlineWidth)); // Collapse borders
  @media (min-width: 900px) {
    flex: 0 0 280.7px;
  }

  &:first-child {
    margin: 0;
  }
}

// The large book item, has a fixed size
.itemLarge {
  flex: 0 0 calc(100vw - 40px);
  min-width: 300px;
  margin-left: calc(-1 * var(--outlineWidth)); // Collapse borders

  @media (min-width: 420px) {
    flex: 0 0 380px;
  }

  @media (min-width: 900px) {
    flex: 0 0 560.5px;
  }
  &:first-child {
    margin: 0;
  }
}

// The half book item, is 50% as wide as it's container
// only below 450px screen width, we have to break out, otherwise it gets too small
.itemHalf {
  flex: 0 0 200px;

  @media (min-width: 450px) {
    flex: 0 0 50%;
  }

  margin-left: calc(-1 * var(--outlineWidth)); // Collapse borders

  &:first-child {
    margin: 0;
  }
}

// Item with styles if you want to set your own flex breakpoints / widths
// pass className into ListItemWithStyles props

.itemWithStyles {
  margin-left: calc(-1 * var(--outlineWidth)); // Collapse borders;
  &:first-child {
    margin: 0;
  }
}
